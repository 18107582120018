import jsonData from '../assets/JSONS/general.json';

// Función para normalizar texto (eliminar acentos y convertir a minúsculas)
const normalizeText = (text) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
};

export const getSistemas = () => {
  return jsonData.subestructuras.map(sistema => ({
    value: sistema.nombre,
    label: sistema.nombre
  }));
};

export const getEstructuras = (sistemaNombre) => {
  const sistema = jsonData.subestructuras.find(s => s.nombre === sistemaNombre);
  if (!sistema || !sistema.subestructuras) return [];
  
  return sistema.subestructuras.map(estructura => ({
    value: estructura.nombre,
    label: estructura.nombre
  }));
};

export const getSubestructuras = (sistemaNombre, estructuraNombre) => {
  const sistema = jsonData.subestructuras.find(s => s.nombre === sistemaNombre);
  if (!sistema) return [];
  const estructura = sistema.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura || !estructura.subestructuras) return [];

  return estructura.subestructuras.map(sub => ({
    value: sub.nombre,
    label: sub.nombre
  }));
};

export const getSubsubestructuras = (sistemaNombre, estructuraNombre, subestructuraNombre) => {
  const sistema = jsonData.subestructuras.find(s => s.nombre === sistemaNombre);
  if (!sistema) return [];
  const estructura = sistema.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura) return [];
  const subestructura = estructura.subestructuras.find(sub => sub.nombre === subestructuraNombre);
  if (!subestructura || !subestructura.subestructuras) return [];

  return subestructura.subestructuras.map(subsub => ({
    value: subsub.nombre,
    label: subsub.nombre
  }));
};

export const getSubsubsubestructuras = (sistemaNombre, estructuraNombre, subestructuraNombre, subsubestructuraNombre) => {
  const sistema = jsonData.subestructuras.find(s => s.nombre === sistemaNombre);
  if (!sistema) return [];
  const estructura = sistema.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura) return [];
  const subestructura = estructura.subestructuras.find(sub => sub.nombre === subestructuraNombre);
  if (!subestructura) return [];
  const subsubestructura = subestructura.subestructuras.find(subsub => subsub.nombre === subsubestructuraNombre);
  if (!subsubestructura || !subsubestructura.subestructuras) return [];

  return subsubestructura.subestructuras.map(subsubsub => ({
    value: subsubsub.nombre,
    label: subsubsub.nombre
  }));
};

export const getProblemas = (sistemaNombre, estructuraNombre, subestructuraNombre, subsubestructuraNombre, subsubsubestructuraNombre) => {
  const sistema = jsonData.subestructuras.find(s => s.nombre === sistemaNombre);
  if (!sistema) return [];
  const estructura = sistema.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura) return [];

  let targetStructure = estructura;

  if (subestructuraNombre) {
    targetStructure = estructura.subestructuras.find(sub => sub.nombre === subestructuraNombre);
    if (!targetStructure) return [];
  }

  if (subsubestructuraNombre) {
    targetStructure = targetStructure.subestructuras.find(subsub => subsub.nombre === subsubestructuraNombre);
    if (!targetStructure) return [];
  }

  if (subsubsubestructuraNombre) {
    targetStructure = targetStructure.subestructuras.find(subsubsub => subsubsub.nombre === subsubsubestructuraNombre);
    if (!targetStructure) return [];
  }

  if (!targetStructure.observaciones) return [];

  return targetStructure.observaciones.map(obs => ({
    value: obs.problema,
    label: obs.problema
  }));
};

export const getDescripcion = (sistemaNombre, estructuraNombre, subestructuraNombre, subsubestructuraNombre, subsubsubestructuraNombre, problemaNombre) => {
  const sistema = jsonData.subestructuras.find(s => s.nombre === sistemaNombre);
  if (!sistema) return '';
  const estructura = sistema.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura) return '';

  let targetStructure = estructura;

  if (subestructuraNombre) {
    targetStructure = estructura.subestructuras.find(sub => sub.nombre === subestructuraNombre);
    if (!targetStructure) return '';
  }

  if (subsubestructuraNombre) {
    targetStructure = targetStructure.subestructuras.find(subsub => subsub.nombre === subsubestructuraNombre);
    if (!targetStructure) return '';
  }

  if (subsubsubestructuraNombre) {
    targetStructure = targetStructure.subestructuras.find(subsubsub => subsubsub.nombre === subsubsubestructuraNombre);
    if (!targetStructure) return '';
  }

  if (!targetStructure.observaciones) return '';

  const observacion = targetStructure.observaciones.find(obs => obs.problema === problemaNombre);
  return observacion ? observacion.descripcion : '';
};

export const tieneSubestructuras = (sistemaNombre, estructuraNombre, subestructuraNombre, subsubestructuraNombre) => {
  const sistema = jsonData.subestructuras.find(s => s.nombre === sistemaNombre);
  if (!sistema) return false;
  const estructura = sistema.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura) return false;

  let targetStructure = estructura;

  if (subestructuraNombre) {
    targetStructure = estructura.subestructuras.find(sub => sub.nombre === subestructuraNombre);
    if (!targetStructure) return false;
  }

  if (subsubestructuraNombre) {
    targetStructure = targetStructure.subestructuras.find(subsub => subsub.nombre === subsubestructuraNombre);
    if (!targetStructure) return false;
  }

  return targetStructure.subestructuras && targetStructure.subestructuras.length > 0;
};

export const getDescriptionSuggestions = (sistemaNombre, estructuraNombre, subestructuraNombre, subsubestructuraNombre, subsubsubestructuraNombre, problemaNombre, currentDescription) => {

  let suggestions = [];

  const collectSuggestions = (structure) => {
    if (structure.observaciones) {
      suggestions = suggestions.concat(structure.observaciones.map(obs => obs.descripcion));
    }
    if (structure.subestructuras) {
      structure.subestructuras.forEach(sub => collectSuggestions(sub));
    }
  };

  if (!sistemaNombre) {
    jsonData.subestructuras.forEach(sistema => collectSuggestions(sistema));
  } else {
    const sistema = jsonData.subestructuras.find(s => s.nombre === sistemaNombre);
    if (sistema) {
      if (!estructuraNombre) {
        collectSuggestions(sistema);
      } else {
        const estructura = sistema.subestructuras.find(e => e.nombre === estructuraNombre);
        if (estructura) {
          if (!subestructuraNombre) {
            collectSuggestions(estructura);
          } else {
            const subestructura = estructura.subestructuras.find(sub => sub.nombre === subestructuraNombre);
            if (subestructura) {
              if (!subsubestructuraNombre) {
                collectSuggestions(subestructura);
              } else {
                const subsubestructura = subestructura.subestructuras.find(subsub => subsub.nombre === subsubestructuraNombre);
                if (subsubestructura) {
                  if (!subsubsubestructuraNombre) {
                    collectSuggestions(subsubestructura);
                  } else {
                    const subsubsubestructura = subsubestructura.subestructuras.find(subsubsub => subsubsub.nombre === subsubsubestructuraNombre);
                    if (subsubsubestructura) {
                      collectSuggestions(subsubsubestructura);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Filtrar sugerencias basadas en la descripción actual
  if (currentDescription) {
    const normalizedCurrentDescription = normalizeText(currentDescription);
    suggestions = suggestions.filter(sugg => 
      normalizeText(sugg).includes(normalizedCurrentDescription)
    );
  }

  // Eliminar duplicados
  suggestions = [...new Set(suggestions)];

  return suggestions;
};

