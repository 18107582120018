import React, { useState, useMemo } from 'react';
import { Checkbox, Input, Row, Col, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const options = {
  COSTILLAS: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
  ESTERNEBRAS: ['1', '2', '3', '4', '5', '6', '7', '8'],
  CARTILAGOS: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
  VERTEBRAS: ['C3', 'C4', 'C5', 'C6', 'C7', 'T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'T11', 'T12', 'T13', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7', 'S1'],
  ESPACIOS: ['C3-C4', 'C4-C5', 'C5-C6', 'C6-C7', 'C7-T1', 'T1-T2', 'T2-T3', 'T3-T4', 'T4-T5', 'T5-T6', 'T6-T7', 'T7-T8', 'T8-T9', 'T9-T10', 'T10-T11', 'T11-T12', 'T12-T13', 'T13-L1', 'L1-L2', 'L2-L3', 'L3-L4', 'L4-L5', 'L5-L6', 'L6-L7', 'L7-S1'],
  ESPACIOS2: ['C3-C4', 'C4-C5', 'C5-C6', 'C6-C7', 'C7-T1', 'T1-T2', 'T2-T3', 'T3-T4', 'T4-T5', 'T5-T6', 'T6-T7', 'T7-T8', 'T8-T9', 'T9-T10', 'T10-T11', 'T11-T12', 'T12-T13', 'T13-L1', 'L1-L2', 'L2-L3', 'L3-L4', 'L4-L5', 'L5-L6', 'L6-L7', 'L7-S1', 'S1-Co1', 'Co1-Co2', 'Co2-Co3', 'Co3-Co4', 'Co4-Co5', 'Co5-Co6', 'Co6-Co7', 'Co7-Co8', 'Co8-Co9', 'Co9-Co10', 'Co10-Co11', 'Co11-Co12', 'Co12-Co13', 'Co13-Co14', 'Co14-Co15', 'Co15-Co16', 'Co16-Co17', 'Co17-Co18', 'Co18-Co19', 'Co19-Co20'], /*Articulaciones intervertebrales C3 a Co20.*/
  ESPACIOS3: ['Co1', 'Co2', 'Co3', 'Co4', 'Co5', 'Co6', 'Co7', 'Co8', 'Co9', 'Co10', 'Co11', 'Co12', 'Co13', 'Co14', 'Co15', 'Co16', 'Co17', 'Co18', 'Co19', 'Co20'], /*1ra vértebra coccígea a 20ma vértebra coccígea*/
  ARTICULACIONES: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'], /*Articulaciones costovertebrales 1ra a 13ra*/
  ARTICULACIONES2: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], /*Articulaciones costocondrales 1ra a 12da*/
  ARTICULACIONES3: ['1', '2', '3', '4', '5', '6', '7', '8'], /*Articulaciones esternocondrales 1ra a 8va*/
  ARTICULACIONES4: ['1', '2', '3', '4', '5', '6', '7'], /*Articulaciones interesternebral 1ra a 7ma*/
};

const getOptionsForSubestructura = (type, subestructura, problema) => {
  switch (subestructura) {
    case 'Costilla 1ra a 13ra':
      return options.COSTILLAS;
    case 'Cartílago costal 1ro a 12mo':
      return options.CARTILAGOS;
    case 'Esternebra 1ra a 8va':
      return options.ESTERNEBRAS;
    case '3ra vértebra cervical a 1er vértebra sacra.':
      if (problema.toLowerCase().includes('intervertebral')) {
        return options.ESPACIOS;
      }
      else {
        return options.VERTEBRAS;
      }
    case 'Articulaciones intervertebrales C3 a Co20':
      return options.ESPACIOS2;
    case '1ra vértebra coccígea a 20ma vértebra coccígea':
      return options.ESPACIOS3;
    case 'Articulaciones costovertebrales 1ra a 13ra':
      return options.ARTICULACIONES;
    case 'Articulaciones costocondrales 1ra a 12da':
      return options.ARTICULACIONES2;
    case 'Articulaciones esternocondrales 1ra a 8va':
      return options.ARTICULACIONES3;
    case 'Articulaciones interesternebral 1ra a 7ma':
      return options.ARTICULACIONES4;
    default:
      return options[type] || [];
  }
};

export const ModalContent = ({ type, selectedOptions, setSelectedOptions, subestructura, problema }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = useMemo(() => {
    const optionsForSubestructura = getOptionsForSubestructura(type, subestructura, problema);
    return optionsForSubestructura.filter(option =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [type, searchTerm, subestructura, problema]);

  const handleChange = (checkedValues) => {
    setSelectedOptions(checkedValues);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedOptions(filteredOptions);
    } else {
      setSelectedOptions([]);
    }
  };

  return (
    <div className="modal-content">
      <Input
        prefix={<SearchOutlined />}
        placeholder="Buscar..."
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <Checkbox
        indeterminate={selectedOptions.length > 0 && selectedOptions.length < filteredOptions.length}
        checked={selectedOptions.length === filteredOptions.length}
        onChange={handleSelectAll}
      >
        Seleccionar todos
      </Checkbox>
      <Divider style={{ margin: '12px 0' }} />
      <Checkbox.Group
        style={{ width: '100%' }}
        value={selectedOptions}
        onChange={handleChange}
      >
        <Row gutter={[16, 8]}>
          {filteredOptions.map(value => (
            <Col span={8} key={value}>
              <Checkbox value={value}>{value}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

