// Assuming the JSON data is imported or fetched and stored in a variable called 'jsonData'
import jsonData from '../assets/JSONS/solicitudes.json';

export const getEstructuras = () => {
  return jsonData.subestructuras.map(estructura => ({
    value: estructura.nombre,
    label: estructura.nombre
  }));
};

export const getSubestructuras = (estructuraNombre) => {
  const estructura = jsonData.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura) return [];

  if (estructura.subestructuras) {
    return estructura.subestructuras.map(sub => ({
      value: sub.nombre,
      label: sub.nombre
    }));
  }

  return [];
};

export const getIncidencias = (estructuraNombre, subestructuraNombre) => {
  const estructura = jsonData.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura) return [];

  if (estructura.incidencias) {
    return estructura.incidencias.map(inc => ({
      value: inc.nombre,
      label: inc.nombre
    }));
  }

  if (estructura.subestructuras) {
    const subestructura = estructura.subestructuras.find(sub => sub.nombre === subestructuraNombre);
    if (subestructura && subestructura.incidencias) {
      return subestructura.incidencias.map(inc => ({
        value: inc.nombre,
        label: inc.nombre
      }));
    }
  }

  return [];
};

export const getRedaccion = (estructuraNombre, subestructuraNombre, incidenciaNombre) => {
  const estructura = jsonData.subestructuras.find(e => e.nombre === estructuraNombre);
  if (!estructura) return '';

  if (estructura.incidencias) {
    const incidencia = estructura.incidencias.find(inc => inc.nombre === incidenciaNombre);
    return incidencia ? incidencia.redacción : '';
  }

  if (estructura.subestructuras) {
    const subestructura = estructura.subestructuras.find(sub => sub.nombre === subestructuraNombre);
    if (subestructura && subestructura.incidencias) {
      const incidencia = subestructura.incidencias.find(inc => inc.nombre === incidenciaNombre);
      return incidencia ? incidencia.redacción : '';
    }
  }

  return '';
};

export const tieneSubestructuras = (estructuraNombre) => {
  const estructura = jsonData.subestructuras.find(e => e.nombre === estructuraNombre);
  return estructura && estructura.subestructuras && estructura.subestructuras.length > 0;
};

