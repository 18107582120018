import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import espera from '../assets/images/espera.webp';
import '../assets/styles/LoginPage.css';
import logo from '../assets/images/logo2.svg';

const Await = () => {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/');
    };
    return (
        <div className="body">
            <Row className="content">
                <Col lg={10} sm={24} xs={24} className="newleftside">
                    <div>
                        <img src={espera} alt="ovejero" className="dogimage2" />
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24}>
                        <Col span={24} className="rightside-container6">
                            <div className="container">
                                <img src={logo} alt="Logo" className="awaitlogo" />
                                <p className="subtitle4">Estamos revisando tu informacion, en breves obtendras una resupuesta</p>
                                <p className="subtitle3">Esto no debería tomar mucho tiempo...</p>
                                <button className="button7" onClick={handleContinue}>
                                    Regresar a la página principal
                                </button>
                            </div>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default Await;
