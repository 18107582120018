import React, { useState } from 'react';
import { Input } from 'antd';
import '../assets/styles/FloatingInput.css';

const FloatingInput = ({ 
  label, 
  value, 
  onChange, 
  name, 
  type = 'text', 
  error, 
  touched, 
  required, 
  disabled, 
  ...props 
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`floating-input ${isFocused || value ? 'focused' : ''} ${error && touched ? 'error' : ''} ${required ? 'required' : ''} ${disabled ? 'disabled' : ''}`}>
      <Input
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        name={name}
        type={type}
        placeholder=" "
        disabled={disabled} 
        {...props}
      />
      <label>{label}{required && '*'}</label>
    </div>
  );
};

export default FloatingInput;

