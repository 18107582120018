import React, { useState, useEffect, useMemo, useCallback } from "react"
import { Space, Table, Tag, Input, Button, Col, Row, message, Dropdown, Menu } from "antd"
import { useNavigate } from "react-router-dom"
import { EditOutlined, CopyOutlined, DownloadOutlined, PlayCircleOutlined } from "@ant-design/icons"
import "../assets/styles/Dashboard.css"
import { useAuth } from "../contexts/authContext"
import { getReportsByVeterinary } from "../services/ReportService"
import ReportModal from "../components/ReportModal"
import { duplicateReport, downloadPDF } from "../services/ReportService"

const { Column } = Table
const { Search } = Input

const Dashboard = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const { getToken } = useAuth()
  const [selectedReport, setSelectedReport] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const fetchData = useCallback(async () => {
    setLoading(true)
    const token = await getToken()
    if (!token) {
      throw new Error("No authentication token found.")
    }

    try {
      const fetchedReports = await getReportsByVeterinary(token)
      console.log("Fetched reports:", fetchedReports)
      setData(fetchedReports)
    } catch (error) {
      console.error("Error fetching reports:", error)
      setData([])
    } finally {
      setLoading(false)
    }
  }, [getToken])

  useEffect(() => {
    fetchData()
  }, [fetchData, getToken])

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase())
  }

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return Object.entries(item).some(([key, value]) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTerm)
        }
        if (typeof value === "object" && value !== null) {
          return JSON.stringify(value).toLowerCase().includes(searchTerm)
        }
        return false
      })
    })
  }, [data, searchTerm])

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      // First, sort by status (in progress first, then completed)
      if (a.status.trim().toLowerCase() === "en progreso" && b.status.trim().toLowerCase() === "completado") {
        return -1
      }
      if (a.status.trim().toLowerCase() === "completado" && b.status.trim().toLowerCase() === "en progreso") {
        return 1
      }
      // If status is the same, sort by date (most recent first)
      return new Date(b.reportDate) - new Date(a.reportDate)
    })
  }, [filteredData])

  const handleViewReport = (record) => {
    setSelectedReport(record)
    setIsModalOpen(true)
  }

  const handleCopyReport = async (record, copyType) => {
    const token = await getToken()
    if (!token) {
      throw new Error("No authentication token found.")
    }
    if (copyType === "follow-up") {
      const report = await duplicateReport(token, record.id)
      if (report) {
        message.success("Reporte copiado para seguimiento")
        fetchData()
      }
    } else {
      console.log("Copy type is not follow-up")
    }
  }

  const handleDownloadOrContinue = async (record) => {
    if (record.status.trim().toLowerCase() === "completado") {
      const token = await getToken()
      if (!token) {
        throw new Error("No authentication token found.")
      }
      await downloadPDF(token, record.id)
    } else {
      console.log("Continuing report for:", record.patient.name)

      // Prepare the formValues object
      const formValues = {
        tutor: record.patient?.guardian?.name || "",
        tutor_email: record.patient?.guardian?.email || "",
        profesional_derivante: record.requestingProfessional?.name || "",
        profesional_derivante_email: record.requestingProfessional?.email || "",
        nombreAnimal: record.patient?.name || "",
        edad: record.patient?.age?.value || "",
        edadUnidad: record.patient?.age?.unit || "",
        sexo: record.patient?.sex || "",
        especie: record.patient?.species || "",
        raza: record.patient?.breed || "",
        fecha: record.reportDate || new Date().toISOString(),
        tipo_estudio: record.type || "",
        tutor_email_disabled: false,
        profesional_email_disabled: false,
      }
      const report = {
        id: record.id, 
      };
      
      // Set the formValues in sessionStorage
      sessionStorage.setItem("formValues", JSON.stringify(formValues))
      sessionStorage.setItem("report", JSON.stringify(report))
      // Navigate to /analisis
      navigate("/analisis")
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString("es-ES", { day: "2-digit", month: "2-digit", year: "numeric" })
  }

  return (
    <Row justify="center" className="bodyy">
      <Col span={20}>
        <Row justify={"space-between"}>
          <Col span={10}>
            <Search
              placeholder="Buscar en todos los campos"
              allowClear
              enterButton="Buscar"
              size="large"
              onChange={(e) => handleSearch(e.target.value)}
              className="mb-4"
            />
          </Col>
          <Col span={6} className="text-rightt">
            <Button
              type="primary"
              size="large"
              className="mt-4 redesigned-secondary-button"
              onClick={() => {
                navigate("/analizar")
              }}
            >
              CREAR NUEVO REPORTE
            </Button>
          </Col>
        </Row>
        <Row className="table-container">
          <Table
            dataSource={sortedData}
            className="dashboard-table"
            loading={loading}
            scroll={{ y: "calc(100vh - 350px)", x: false }}
            pagination={{ position: ["bottomCenter"], pageSize: 15 }}
          >
            {/* <ColumnGroup title="Paciente"> */}
            <Column title="Nombre" dataIndex={["patient", "name"]} key="patientName" width="10%" />
            {/* </ColumnGroup> */}
            {/* <ColumnGroup title="Propietario"> */}
            <Column title="Tutor" dataIndex={["patient", "guardian", "name"]} key="guardianName" width="10%" />
            {/* </ColumnGroup> */}
            <Column
              title="Fecha de reporte"
              dataIndex="reportDate"
              key="reportDate"
              render={(text) => formatDate(text)}
              width="8%"
            />
            <Column
              title="Derivante"
              dataIndex={["requestingProfessional", "name"]}
              key="requestingProfessional"
              width="10%"
            />
            <Column title="Tipo de estudio" dataIndex="type" key="studyType" width="8%" />
            <Column
              title="Estado"
              dataIndex="status"
              key="status"
              render={(status) => (
                <Tag color={status.trim().toLowerCase() === "en progreso" ? "volcano" : "green"}>
                  {status.toUpperCase()}
                </Tag>
              )}
              width="10%"
            />
            <Column
              title="Acción"
              key="action"
              render={(_, record) => (
                <Space size="small">
                  <button
                    className={`action-button ${record.status.trim().toLowerCase() === "completado" ? "download-button" : "continue-button"}`}
                    onClick={() => handleDownloadOrContinue(record)}
                  >
                    {record.status.trim().toLowerCase() === "completado" ? (
                      <>
                        <DownloadOutlined /> Descargar
                      </>
                    ) : (
                      <>
                        <PlayCircleOutlined /> Continuar
                      </>
                    )}
                  </button>
                  <button className="action-button view-button" onClick={() => handleViewReport(record)}>
                    <EditOutlined />
                  </button>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="1" onClick={() => handleCopyReport(record, "complete")}>
                          Copiar completo
                        </Menu.Item>
                        <Menu.Item key="2" onClick={() => handleCopyReport(record, "follow-up")}>
                          Copia para seguimiento
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <button className="action-button copy-button">
                      <CopyOutlined />
                    </button>
                  </Dropdown>
                </Space>
              )}
              width="15%"
            />
          </Table>
        </Row>
      </Col>
      <ReportModal report={selectedReport} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Row>
  )
}

export default Dashboard

