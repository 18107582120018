import React, { useEffect, useRef, useCallback } from "react"
import { Button } from "antd"
import { AudioOutlined, AudioMutedOutlined } from "@ant-design/icons"

const INACTIVITY_TIMEOUT = 5000 // 5 segundos de inactividad

const MicrophoneButton = ({ onTranscriptUpdate, onTranscriptComplete, isActive, setIsActive }) => {
  const recognitionRef = useRef(null)
  const transcriptRef = useRef("")
  const inactivityTimerRef = useRef(null)
  const isStoppingRef = useRef(false)
  const completeTranscriptRef = useRef("")

  const stopListening = useCallback(() => {
    if (recognitionRef.current && !isStoppingRef.current) {
      isStoppingRef.current = true
      recognitionRef.current.stop()
      if (completeTranscriptRef.current) {
        onTranscriptComplete(completeTranscriptRef.current)
        completeTranscriptRef.current = ""
      }
      transcriptRef.current = ""
    }
    setIsActive(false)
    clearTimeout(inactivityTimerRef.current)
  }, [onTranscriptComplete, setIsActive])

  const capitalizeFirstLetter = (text) => {
    if (!text) return ""
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  const startListening = useCallback(() => {
    if ("webkitSpeechRecognition" in window) {
      const SpeechRecognition = window.webkitSpeechRecognition
      recognitionRef.current = new SpeechRecognition()
      recognitionRef.current.continuous = true
      recognitionRef.current.interimResults = true
      recognitionRef.current.lang = "es-ES"

      recognitionRef.current.onstart = () => {
        setIsActive(true)
        transcriptRef.current = ""
        completeTranscriptRef.current = ""
        isStoppingRef.current = false
      }

      recognitionRef.current.onresult = (event) => {
        if (isStoppingRef.current) return

        let interimTranscript = ""
        let finalTranscript = ""

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript
          } else {
            interimTranscript += event.results[i][0].transcript
          }
        }

        if (finalTranscript) {
          completeTranscriptRef.current += "" + capitalizeFirstLetter(finalTranscript)
          completeTranscriptRef.current = completeTranscriptRef.current.trim()
          onTranscriptUpdate(completeTranscriptRef.current)
        }

        transcriptRef.current = interimTranscript
        onTranscriptUpdate(completeTranscriptRef.current + "" + interimTranscript)

        // Reiniciar el temporizador de inactividad
        clearTimeout(inactivityTimerRef.current)
        inactivityTimerRef.current = setTimeout(() => {
          if (completeTranscriptRef.current) {
            onTranscriptComplete(completeTranscriptRef.current)
            completeTranscriptRef.current = ""
          }
          stopListening()
        }, INACTIVITY_TIMEOUT)
      }

      recognitionRef.current.onerror = (event) => {
        console.error("Speech recognition error", event.error)
        stopListening()
      }

      recognitionRef.current.onend = () => {
        if (isActive && !isStoppingRef.current) {
          recognitionRef.current.start()
        } else if (completeTranscriptRef.current) {
          onTranscriptComplete(completeTranscriptRef.current)
          completeTranscriptRef.current = ""
        }
      }

      recognitionRef.current.start()
    } else {
      console.error("Speech recognition not supported")
    }
  }, [isActive, onTranscriptUpdate, onTranscriptComplete, stopListening, setIsActive])

  const toggleDictation = useCallback(() => {
    if (isActive) {
      stopListening()
    } else {
      startListening()
    }
  }, [isActive, startListening, stopListening])

  useEffect(() => {
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop()
      }
      clearTimeout(inactivityTimerRef.current)
    }
  }, [])

  return (
    <Button
      icon={isActive ? <AudioOutlined /> : <AudioMutedOutlined />}
      onClick={toggleDictation}
      aria-label={isActive ? "Detener dictado" : "Iniciar dictado"}
      style={{
        position: "absolute",
        top: "5px",
        right: "5px",
        background: "none",
        border: "1px solid #ccc",
        borderRadius: "4px",
        zIndex: 1,
      }}
    />
  )
}

export default MicrophoneButton

