const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const askVertex = async (prompt, token) => {
    sessionStorage.removeItem('vertexResponse');

    const response = await fetch(`${REACT_APP_BFF_URL}/ask-vertex`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({question: prompt}),
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.detail || 'Error occurred while asking Vertex.');

    }

    return data;

};