export function fixEncodingIssues(text) {
  const replacements = {
    'Ã³': 'ó',
    'Ã­': 'í',
    'Ã¡': 'á',
    'Ã©': 'é',
    'Ãº': 'ú',
    'Ã±': 'ñ',
  };

  return text.replace(/Ã.|Ã/g, match => replacements[match] || match);
}
