import React, { useCallback, useEffect, useState } from "react"
import { Button, Input, message, Modal, Tooltip, Checkbox } from "antd"
import { DownloadOutlined, ExperimentOutlined, LoadingOutlined } from "@ant-design/icons"
import { getObservations } from "../services/GeminiServices"
import { useAuth } from "../contexts/authContext"
import {addDiagnosis, addFindings, addNotes, addRequest, generateDynamicPDF} from "../services/ReportService";

const { TextArea } = Input

const InformeModal = ({
  isVisible,
  onClose,
  diagnostico,
  solicitud,
  observaciones,
  images,
  updateRightColumnState,
}) => {
  const [editedSolicitud, setEditedSolicitud] = useState(solicitud)
  const [editedObservaciones, setEditedObservaciones] = useState(observaciones.join("\n"))
  const [editedDiagnostico, setEditedDiagnostico] = useState(diagnostico)
  const [editedNotas, setEditedNotas] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  const [diagnosticoSeparado, setDiagnosticoSeparado] = useState(false)

  const { getToken } = useAuth()

  const updateLocalState = useCallback((field, value) => {
    switch (field) {
      case "solicitud":
        setEditedSolicitud(value)
        break
      case "observaciones":
        setEditedObservaciones(value)
        break
      case "diagnostico":
        setEditedDiagnostico(value)
        break
      case "notas":
        setEditedNotas(value)
        break
      default:
        console.warn(`Unhandled field in updateLocalState: ${field}`)
    }
  }, [])

  useEffect(() => {
    updateLocalState("solicitud", solicitud)
    updateLocalState("observaciones", observaciones.join("\n"))
    updateLocalState("diagnostico", diagnostico)
  }, [solicitud, observaciones, diagnostico, updateLocalState])

  const handleChange = useCallback(
    (field, value) => {
      updateLocalState(field, value)
      if (field === "observaciones") {
        updateRightColumnState(field, value.split("\n"))
      } else {
        updateRightColumnState(field, value)
      }
    },
    [updateLocalState, updateRightColumnState],
  )

  const handleMagicPencil = async () => {
    setIsLoading(true)
    try {
      const prompt = `${editedSolicitud}. ${editedObservaciones}`

      const token = await getToken()
      if (!token) {
        throw new Error("No authentication token found.")
      }

      const result = await getObservations(prompt, token)
      if (result && result.response) {
        const observations = result.response.replace("Observaciones:\n", "").trim()
        handleChange("observaciones", observations)
        message.success("Observaciones actualizadas con éxito.")
      } else {
        message.error("No se pudo obtener observaciones. Por favor, inténtelo de nuevo.")
      }
    } catch (error) {
      console.error("Error al obtener observaciones:", error)
      message.error("Hubo un error al procesar su solicitud. Por favor, inténtelo de nuevo.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleGenerateDynamicPDF = async () => {
    setIsGeneratingPDF(true)
    try {
      const token = await getToken()
      if (!token) {
        throw new Error("No authentication token found.")
      }

      // Process notes into an array
      const notesArray = editedNotas.split("\n").filter((note) => note.trim() !== "")

      // Initiate all three requests concurrently
      const reportId = JSON.parse(sessionStorage.getItem("report")).id
      await addRequest(token, reportId)
      await addFindings(token, reportId)
      await addDiagnosis(token, reportId)
      await addNotes(token, reportId, notesArray)
      await generateDynamicPDF(token, diagnosticoSeparado)

      // Log the new fields
      console.log("Notas:", notesArray)
      console.log("Diagnóstico en hoja separada:", diagnosticoSeparado)

      // Provide feedback to the user upon success
      message.success("Informe generado y PDF creado exitosamente.")
      onClose()
    } catch (error) {
      console.error("Error generating PDF:", error)
      message.error("Hubo un error al generar el informe. Por favor, inténtelo de nuevo.")
    } finally {
      setIsGeneratingPDF(false)
    }
  }

  return (
    <Modal
      className="vistaprevia-modal"
      title="Vista previa de informe"
      open={isVisible}
      onCancel={isGeneratingPDF ? undefined : onClose}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Button
            key="cancel"
            onClick={onClose}
            disabled={isGeneratingPDF}
            className="button8 redesigned-danger-button"
          >
            Cerrar
          </Button>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={diagnosticoSeparado}
              onChange={(e) => setDiagnosticoSeparado(e.target.checked)}
              style={{ marginRight: "10px" }}
              className="checkboxx"
            >
              Diagnóstico en hoja separada
            </Checkbox>
            <Button
              key="download"
              className="generate-button2 vistaprevia-button"
              onClick={handleGenerateDynamicPDF}
              loading={isGeneratingPDF}
              disabled={
                isGeneratingPDF || !editedSolicitud.trim() || !editedObservaciones.trim() || !editedDiagnostico.trim()
              }
            >
              <DownloadOutlined />
              {isGeneratingPDF ? "Generando..." : "Descargar Informe"}
            </Button>
          </div>
        </div>
      }
      width={900}
    >
      <div style={{ marginBottom: 10 }}>
        <h3 className="preview-title">Solicitud:</h3>
        <TextArea value={editedSolicitud} onChange={(e) => handleChange("solicitud", e.target.value)} rows={3} />
      </div>
      <div style={{ marginBottom: 10 }}>
        <h3 className="preview-title">Observaciones:</h3>
        <div style={{ display: "flex", alignItems: "flex-start", position: "relative" }}>
          <TextArea
            value={editedObservaciones}
            onChange={(e) => handleChange("observaciones", e.target.value)}
            rows={6}
            style={{ width: "100%" }}
          />
          <Tooltip title="Completado mágico">
            <Button
              icon={isLoading ? <LoadingOutlined /> : <ExperimentOutlined />}
              onClick={handleMagicPencil}
              disabled={isLoading}
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                backgroundColor: "#1890ff",
                borderColor: "#1890ff",
                color: "white",
                boxShadow: "0 2px 0 rgba(0, 0, 0, 0.045)",
                transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
              }}
              onMouseEnter={(e) => {
                if (!isLoading) {
                  e.currentTarget.style.backgroundColor = "#40a9ff"
                  e.currentTarget.style.borderColor = "#40a9ff"
                }
              }}
              onMouseLeave={(e) => {
                if (!isLoading) {
                  e.currentTarget.style.backgroundColor = "#1890ff"
                  e.currentTarget.style.borderColor = "#1890ff"
                }
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <h3 className="preview-title">Diagnóstico:</h3>
        <TextArea value={editedDiagnostico} onChange={(e) => handleChange("diagnostico", e.target.value)} rows={4} />
      </div>
      <div>
        <h3 className="preview-title">Notas (opcional):</h3>
        <TextArea
          value={editedNotas}
          onChange={(e) => handleChange("notas", e.target.value)}
          rows={3}
          placeholder="Ingrese notas adicionales aquí (opcional)"
        />
      </div>
    </Modal>
  )
}

export default InformeModal

