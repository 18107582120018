import axios from 'axios';

const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const generateDynamicPDF = async (token, separado) => {
    try {
        try {
            // Retrieve and parse report data from sessionStorage
            const reportString = sessionStorage.getItem("report");
            if (!reportString) {
                throw new Error("No report data found in session.");
            }

            let report;
            try {
                report = JSON.parse(reportString);
            } catch (parseError) {
                throw new Error("Invalid report data format.");
            }

            const {id} = report;

            if (!id) {
                throw new Error("Incomplete report data.");
            }

            // Make the POST request to generate the PDF
            const response = await fetch(`${REACT_APP_BFF_URL}/generate-pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Corrected syntax using template literals
                },
                body: JSON.stringify({
                    id: id,
                    newPageDiagnoses: separado
                }),
            });

            if (!response.ok) {
                // Attempt to parse the error response as JSON
                let errorMessage = 'Error occurred while generating PDF.';
                try {
                    const errorData = await response.json();
                    errorMessage = errorData.detail || errorMessage;
                } catch (jsonError) {
                    // If response is not JSON, retain the default error message
                }
                throw new Error(errorMessage);
            }

            // Handle the binary PDF data
            const blob = await response.blob();

            // Create a temporary URL for the blob
            const url = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));

            const metadataResponse = await axios.get(`${process.env.REACT_APP_BFF_URL}/reports/${id}/pdf-metadata`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                responseType: 'json' // Expecting JSON response
            });

            const { filename } = metadataResponse.data;

            if (!filename) {
                throw new Error("Filename not provided in metadata.");
            }

            // Create a temporary anchor element to initiate the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}`); // Set the desired file name

            // Append the anchor to the document body and trigger a click
            document.body.appendChild(link);
            link.click();

            // Clean up by removing the anchor and revoking the object URL
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            // Optional: Inform the user of the successful download initiation

        } catch (error) {
            console.error('Error generating PDF:', error);
            // Display a user-friendly error message
        }
    } catch (error) {
        console.error('Error generating PDF:', error);
        // Optional: Display a user-friendly error message using a notification system
        // Example using a toast notification:
        // toast.error(error.message || 'An unexpected error occurred.');
    }
};

export const createReport = async (token, formData) => {
    try {
        // Send the POST request using Axios
        const response = await axios.post(`${process.env.REACT_APP_BFF_URL}/reports`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        });


        return response.data;
    } catch (error) {
        // Handle errors appropriately
        if (error.response && error.response.data) {
            // Backend returned an error response
            throw new Error(error.response.data.detail || 'Error occurred while creating report.');
        } else {
            // Network or other errors
            throw new Error(error.message || 'Error occurred while creating report.');
        }
    }
};

export const addRequest = async (token, requestId) => {
    try {
        const requestData = JSON.parse(sessionStorage.getItem("formData2")).redaccion;

        const response = await axios.put(
            `${process.env.REACT_APP_BFF_URL}/reports/${requestId}/requests`,
            {request: requestData}, // Directly send the payload
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        // Handle errors appropriately
        if (error.response && error.response.data) {
            // Backend returned an error response
            throw new Error(error.response.data.detail || 'Error occurred while creating request.');
        } else {
            // Network or other errors
            throw new Error(error.message || 'Error occurred while creating request.');
        }
    }
};

export const addDiagnosis = async (token, requestId) => {
    try {
        const diagnosisData = JSON.parse(sessionStorage.getItem("vertexResponse"));

        const response = await axios.put(
            `${process.env.REACT_APP_BFF_URL}/reports/${requestId}/diagnosis`,
            {diagnosis: diagnosisData}, // Directly send the payload
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        // Handle errors appropriately
        if (error.response && error.response.data) {
            // Backend returned an error response
            throw new Error(error.response.data.detail || 'Error occurred while creating diagnosis.');
        } else {
            // Network or other errors
            throw new Error(error.message || 'Error occurred while creating diagnosis.');
        }
    }
};

export const addFindings = async (token, requestId) => {
    try {
        const findingsData = JSON.parse(sessionStorage.getItem("formData2")).observations;

        const response = await axios.post(
            `${process.env.REACT_APP_BFF_URL}/reports/${requestId}/findings`,
            {findings: findingsData}, // Directly send the payload
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        // Handle errors appropriately
        if (error.response && error.response.data) {
            // Backend returned an error response
            throw new Error(error.response.data.detail || 'Error occurred while creating findings.');
        } else {
            // Network or other errors
            throw new Error(error.message || 'Error occurred while creating findings.');
        }
    }
};

export const updateReport = async (token, reportId, formData) => {
    try {
        // Send the PUT request using Axios
        const response = await axios.put(`${process.env.REACT_APP_BFF_URL}/reports/${reportId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        });


        return response.data;
    } catch (error) {
        // Handle errors appropriately
        if (error.response && error.response.data) {
            // Backend returned an error response
            throw new Error(error.response.data.detail || 'Error occurred while updating report.');
        } else {
            // Network or other errors
            throw new Error(error.message || 'Error occurred while updating report.');
        }
    }

}

export const getReportsByVeterinary = async (token) => {
    console.log(token)
    try {
        const response = await axios.get(`${REACT_APP_BFF_URL}/reports/my-veterinary`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        return response.data
    }
    catch (error) {
        if (error.response && error.response.data) {
            // Backend returned an error response
            throw new Error(error.response.data.detail || 'Error occurred while getting reports by veterinary.');
        } else {
            // Network or other errors
            throw new Error(error.message || 'Error occurred while getting reports by veterinary');
        }
    }
}

export const addNotes = async (token, reportId, notes) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BFF_URL}/reports/${reportId}/notes`,
            {notes: notes},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        // Handle errors appropriately
        if (error.response && error.response.data) {
            // Backend returned an error response
            throw new Error(error.response.data.detail || 'Error occurred while creating findings.');
        } else {
            // Network or other errors
            throw new Error(error.message || 'Error occurred while creating findings.');
        }
    }

}

export const downloadPDF = async (token, reportId) => {
    try {
        // Step 1: Fetch metadata to get the filename
        const metadataResponse = await axios.get(`${process.env.REACT_APP_BFF_URL}/reports/${reportId}/pdf-metadata`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            responseType: 'json'
        });

        const { filename } = metadataResponse.data;

        // Step 2: Download the PDF binary data
        const pdfResponse = await axios.get(`${process.env.REACT_APP_BFF_URL}/reports/${reportId}/pdf`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            responseType: 'blob' // Handle binary data
        });

        const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });

        // Create a link element for download
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;

        // Append to the document and trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

    } catch (error) {
        if (error.response && error.response.data) {
            // Backend returned an error response
            const errorMessage = error.response.data.detail || 'Error occurred while downloading PDF.';
            console.error(errorMessage);
            // Optionally, display the error to the user using a notification system
        } else {
            // Network or other errors
            console.error(error.message || 'Error occurred while downloading PDF.');
            // Optionally, display the error to the user using a notification system
        }
    }
}

export const duplicateReport = async (token, reportId) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BFF_URL}/reports/${reportId}/duplicate-partial`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            // Backend returned an error response
            throw new Error(error.response.data.detail || 'Error occurred while duplicating report.');
        } else {
            // Network or other errors
            throw new Error(error.message || 'Error occurred while duplicating report.');
        }
    }
}