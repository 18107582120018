export const getTokenFromSessionStorage = (keyName) => {
    return sessionStorage.getItem(keyName);
};

export const setTokenToSessionStorage = (keyName, token) => {
    sessionStorage.setItem(keyName, token);
}

export const removeTokenFromSessionStorage = (keyName) => {
    sessionStorage.removeItem(keyName);
}