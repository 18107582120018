import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import '../assets/styles/FloatingDatePicker.css';

// Registrar el idioma español
registerLocale('es', es);

const FloatingDatePicker = ({
  label,
  value,
  onChange,
  error,
  touched,
  required,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`floating-input date-picker-wrapper ${isFocused || value ? 'focused' : ''} ${error && touched ? 'error' : ''} ${required ? 'required' : ''}`}>
      <div className="datepicker-container">
        <DatePicker
          selected={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          dateFormat="dd/MM/yyyy"
          placeholderText=" "
          className="ant-input custom-datepicker"
          locale="es"
        />
      </div>
      <label>{label}{required && '*'}</label>
    </div>
  );
};

export default FloatingDatePicker;

