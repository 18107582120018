import React from 'react';
import { Col, Row, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import samu from '../assets/images/samu.webp';
import '../assets/styles/LoginPage.css';


const PreConfirmation = () => {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/espera');
    };
    return (
        <div className="body">
            <Row className="content">
                <Col lg={10} sm={24} xs={24} className="newleftside">
                    <div>
                        <img src={samu} alt="ovejero" className="dogimage2" />
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24}>
                        <Col span={24} className="rightside-container6">
                            <div className="container">
                                <p className="bluetitle decrease left">Bienvenido</p>
                                <p className="subtitle3">Necesitamos unos datos de tu veterinaria mas, para poder terminar de procesar tu solicitud</p>
                                <Input placeholder="Nombre Legal" className="loginInput3" />
                                <Input placeholder="Dirección" className="loginInput3" />
                                <Input placeholder="Numero de Teléfono" className="loginInput3" />
                                <button className="button6" onClick={handleContinue}>
                                    Continuar
                                </button>
                            </div>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default PreConfirmation;
