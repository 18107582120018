const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const createUser = async (uid, email, givenName, familyName, profilePicture) => {
    const response = await fetch(`${REACT_APP_BFF_URL}/users`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uid: uid,
            email: email,
            given_name: givenName,
            family_name: familyName,
            profile_picture: profilePicture
        })
    });
    return response.json();
};