import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { Button, Col, Image, Row, Slider } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined, ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import '../assets/styles/Analisis.css';
import { RightColumn } from '../components/RightColumn';
import { ImageContext } from '../contexts/imageContext/imageContext';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DraggableImage = ({ img, index, moveImage, setSelectedImage, setPosition, setZoom }) => {
  const ref = useRef(null);
  const { images } = useContext(ImageContext);

  const [{ handlerId }, drop] = useDrop({
    accept: 'IMAGE',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'IMAGE',
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} className="image-thumbnail" style={{ opacity }} data-handler-id={handlerId}>
      <img
        src={images[index] || "/placeholder.svg"}
        alt={`Thumbnail ${index}`}
        onClick={() => {
          setSelectedImage(images[index]);
          setPosition({ x: 0, y: 0 });
          setZoom(1);
        }}
        className="thumbnail-image"
      />
    </div>
  );
};

const Analisis = () => {
    const { images, setImages } = useContext(ImageContext);

    const [selectedImage, setSelectedImage] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [invert, setInvert] = useState(0);
    const [contrast, setContrast] = useState(100);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const [regenerateCount, setRegenerateCount] = useState(() => {
        const savedCount = sessionStorage.getItem('regenerateCount');
        return savedCount ? parseInt(savedCount, 10) : 0;
    });
    const [isDrawingMode, setIsDrawingMode] = useState(false);
    const [arrowsByImage, setArrowsByImage] = useState({});
    const [currentArrow, setCurrentArrow] = useState(null);
    const [isDrawingArrow, setIsDrawingArrow] = useState(false);
    const [isEraserMode, setIsEraserMode] = useState(false);
    const [rightColumnWidth, setRightColumnWidth] = useState(400);
    const [isDraggingResize, setIsDraggingResize] = useState(false);
    const [isWidthLoaded, setIsWidthLoaded] = useState(false);
    const dragStartX = useRef(0);
    const dragStartWidth = useRef(0);
    const canvasRef = useRef(null);

    useEffect(() => {
        if (images.length > 0) {
            setSelectedImage(images[0]);
        }
    }, [images]);

    useEffect(() => {
        sessionStorage.setItem('regenerateCount', regenerateCount.toString());
    }, [regenerateCount]);

    const handleZoomIn = () => {
        setZoom(prevZoom => Math.min(prevZoom + 0.1, 3));
    };

    const handleZoomOut = () => {
        setZoom(prevZoom => Math.max(prevZoom - 0.1, 0.5));
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        setDragging(true);
        setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            const newX = e.clientX - startPosition.x;
            const newY = e.clientY - startPosition.y;

            const limitedX = Math.min(Math.max(newX, -((zoom - 1) * 400)), (zoom - 1) * 400);
            const limitedY = Math.min(Math.max(newY, -((zoom - 1) * 296)), (zoom - 1) * 296);

            setPosition({ x: limitedX, y: limitedY });
        }
    };

    const handleMouseUp = () => setDragging(false);

    const handleCanvasClick = (e) => {
        if (!isDrawingMode && !isEraserMode) return;
        if (!canvasRef.current) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const x = (e.clientX - rect.left - position.x) / zoom;
        const y = (e.clientY - rect.top - position.y) / zoom;

        if (isDrawingMode) {
            if (!isDrawingArrow) {
                setCurrentArrow({ start: { x, y } });
                setIsDrawingArrow(true);
            } else {
                const newArrow = { ...currentArrow, end: { x, y } };
                setArrowsByImage(prevArrows => ({
                    ...prevArrows,
                    [selectedImage]: [...(prevArrows[selectedImage] || []), newArrow]
                }));
                setCurrentArrow(null);
                setIsDrawingArrow(false);
            }
        } else if (isEraserMode) {
            eraseArrowsNear(x, y);
        }
    };

    const handleCanvasMouseMove = (e) => {
        if (!isDrawingMode || !isDrawingArrow || !currentArrow || !canvasRef.current) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const x = (e.clientX - rect.left - position.x) / zoom;
        const y = (e.clientY - rect.top - position.y) / zoom;

        setCurrentArrow(prev => ({ ...prev, end: { x, y } }));
    };

    const eraseArrowsNear = (x, y) => {
        const eraserRadius = 10;
        setArrowsByImage(prevArrows => {
            const currentImageArrows = prevArrows[selectedImage] || [];
            const remainingArrows = currentImageArrows.filter(arrow => {
                return !isPointNearLine(x, y, arrow.start, arrow.end, eraserRadius);
            });
            return {
                ...prevArrows,
                [selectedImage]: remainingArrows
            };
        });
    };

    const isPointNearLine = (x, y, start, end, maxDistance) => {
        const A = x - start.x;
        const B = y - start.y;
        const C = end.x - start.x;
        const D = end.y - start.y;

        const dot = A * C + B * D;
        const lenSq = C * C + D * D;
        let param = -1;
        if (lenSq !== 0) {
            param = dot / lenSq;
        }

        let xx, yy;

        if (param < 0) {
            xx = start.x;
            yy = start.y;
        } else if (param > 1) {
            xx = end.x;
            yy = end.y;
        } else {
            xx = start.x + param * C;
            yy = start.y + param * D;
        }

        const dx = x - xx;
        const dy = y - yy;
        const distance = Math.sqrt(dx * dx + dy * dy);

        return distance <= maxDistance;
    };

    const drawArrows = useCallback(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const drawArrow = (start, end, color) => {
            const headlen = 10 / zoom;
            const dx = end.x - start.x;
            const dy = end.y - start.y;
            const angle = Math.atan2(dy, dx);

            ctx.beginPath();
            ctx.moveTo(start.x, start.y);
            ctx.lineTo(end.x, end.y);
            ctx.strokeStyle = color;
            ctx.lineWidth = 2 / zoom;
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(end.x, end.y);
            ctx.lineTo(end.x - headlen * Math.cos(angle - Math.PI / 6), end.y - headlen * Math.sin(angle - Math.PI / 6));
            ctx.lineTo(end.x - headlen * Math.cos(angle + Math.PI / 6), end.y - headlen * Math.sin(angle + Math.PI / 6));
            ctx.fillStyle = color;
            ctx.fill();
        };

        const currentImageArrows = arrowsByImage[selectedImage] || [];
        currentImageArrows.forEach((arrow, index) => {
            const start = {
                x: (arrow.start.x * zoom) + position.x,
                y: (arrow.start.y * zoom) + position.y
            };
            const end = {
                x: (arrow.end.x * zoom) + position.x,
                y: (arrow.end.y * zoom) + position.y
            };

            const color = index % 2 === 0 ? '#FF6F00' : '#00FF00';
            drawArrow(start, end, color);
        });

        if (currentArrow && currentArrow.end) {
            const start = {
                x: (currentArrow.start.x * zoom) + position.x,
                y: (currentArrow.start.y * zoom) + position.y
            };
            const end = {
                x: (currentArrow.end.x * zoom) + position.x,
                y: (currentArrow.end.y * zoom) + position.y
            };

            const color = currentImageArrows.length % 2 === 0 ? '#FFCC80' : '#28a745';
            drawArrow(start, end, color);
        }
    }, [arrowsByImage, zoom, position, currentArrow, selectedImage]);

    const handleContrastChange = (value) => {
        setContrast(value);
    };

    const handleInvertChange = (value) => {
        setInvert(value);
    };

    useEffect(() => {
        if (canvasRef.current) {
            drawArrows();
        }
    }, [drawArrows]);

    const handleDrawArrow = () => {
        setIsDrawingMode(!isDrawingMode);
        setIsEraserMode(false);
        setIsDrawingArrow(false);
        setCurrentArrow(null);
    };

    const handleEraser = () => {
        setIsEraserMode(!isEraserMode);
        setIsDrawingMode(false);
        setIsDrawingArrow(false);
        setCurrentArrow(null);
    };

    const handleResizeStart = (e) => {
        e.preventDefault();
        setIsDraggingResize(true);
        dragStartX.current = e.clientX;
        dragStartWidth.current = rightColumnWidth;
    };

    const handleResize = useCallback((e) => {
        if (!isDraggingResize) return;
        const deltaX = dragStartX.current - e.clientX;
        const newWidth = Math.max(350, Math.min(600, dragStartWidth.current + deltaX));
        setRightColumnWidth(newWidth);
        localStorage.setItem('rightColumnWidth', newWidth.toString());
    }, [isDraggingResize]);

    const handleResizeEnd = () => {
        setIsDraggingResize(false);
    };

    useEffect(() => {
        if (isDraggingResize) {
            window.addEventListener('mousemove', handleResize);
            window.addEventListener('mouseup', handleResizeEnd);
        }
        return () => {
            window.removeEventListener('mousemove', handleResize);
            window.removeEventListener('mouseup', handleResizeEnd);
        };
    }, [isDraggingResize, handleResize]);

    useEffect(() => {
        const savedWidth = localStorage.getItem('rightColumnWidth');
        const initialWidth = savedWidth ? parseInt(savedWidth, 10) : 400;
        setRightColumnWidth(initialWidth);
        setIsWidthLoaded(true);
    }, []);

    const moveImage = useCallback((dragIndex, hoverIndex) => {
        setImages(prevImages => {
            const newImages = [...prevImages];
            const [removed] = newImages.splice(dragIndex, 1);
            newImages.splice(hoverIndex, 0, removed);
            return newImages;
        });
    }, [setImages]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="analysis-container" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                <Row className="content content2">
                    <Col md={2} sm={24} xs={24} className="columna-izq">
                        {images.map((img, index) => (
                            <DraggableImage
                                key={index}
                                img={img}
                                index={index}
                                moveImage={moveImage}
                                setSelectedImage={setSelectedImage}
                                setPosition={setPosition}
                                setZoom={setZoom}
                            />
                        ))}
                    </Col>
                    <Col 
                        className="columna-cen"
                        style={isWidthLoaded ? { width: `calc(100% - 16.66% - ${rightColumnWidth}px)` } : {}}
                    >
                        {images.length > 0 ? (
                            <div className="image-container">
                                <Row className="superior-col">
                                    <Col>
                                        <Button icon={<ZoomInOutlined className="zoom" />} onClick={handleZoomIn} className="zoom" />
                                        <Button icon={<ZoomOutOutlined className="zoom" />} onClick={handleZoomOut} className="zoom" />
                                        <Button
                                            icon={<ArrowRightOutlined className="zoom2" />}
                                            onClick={handleDrawArrow}
                                            className={`arrows zoom2 ${isDrawingMode ? 'active' : ''}`}
                                            aria-label={isDrawingMode ? "Desactivar modo de dibujo de flechas" : "Activar modo de dibujo de flechas"}
                                        />
                                        <Button
                                            icon={<DeleteOutlined className="zoom2" />}
                                            onClick={handleEraser}
                                            className={`arrows zoom2 ${isEraserMode ? 'active' : ''}`}
                                            aria-label={isEraserMode ? "Desactivar goma de borrar" : "Activar goma de borrar"}
                                        />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Slider
                                                min={100}
                                                max={500}
                                                value={contrast}
                                                onChange={handleContrastChange}
                                                className="contrast-slider"
                                            />
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={invert}
                                                onChange={handleInvertChange}
                                                className="invert-slider"
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                                <div
                                    className="image-wrapper"
                                    onMouseDown={handleMouseDown}
                                    onClick={handleCanvasClick}
                                    onMouseMove={handleCanvasMouseMove}
                                    style={{
                                        cursor: isDrawingMode ? 'crosshair' : (isEraserMode ? 'cell' : (zoom > 1 ? (dragging ? 'grabbing' : 'grab') : 'default')),
                                    }}
                                >
                                    {(selectedImage || images[0]) && (
                                        <Image
                                            src={selectedImage || images[0]}
                                            alt="Selected"
                                            preview={false}
                                            className="full-image no-select"
                                            style={{
                                                transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                                                filter: `contrast(${contrast}%) invert(${invert}%)`,
                                            }}
                                        />
                                    )}
                                    <canvas
                                        ref={canvasRef}
                                        width={800}
                                        height={592}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            pointerEvents: 'none',
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <p className="default-text">No hay imágenes disponibles</p>
                        )}
                    </Col>

                    <div 
                        className="columna-der-resizable"
                        style={isWidthLoaded ? { width: `${rightColumnWidth}px` } : {}}
                    >
                        <div 
                            className="resize-handle"
                            onMouseDown={handleResizeStart}
                        ></div>
                        <RightColumn
                            regenerateCount={regenerateCount}
                            setRegenerateCount={setRegenerateCount}
                            setIsModalVisible={() => {}}
                            width={rightColumnWidth}
                        />
                    </div>
                </Row>
            </div>
        </DndProvider>
    );
};

export default Analisis;