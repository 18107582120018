import React, { useState, useEffect, useCallback } from 'react';
import { Input, Button, Row } from 'antd';
import FloatingSelect from './FloatingSelect';
import { getEstructuras, getSubestructuras, getIncidencias, getRedaccion, tieneSubestructuras } from '../utils/solicitudDataExtractor';
import { PlusCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;

export const RequestSection = ({
  selectedStructure,
  setSelectedStructure,
  selectedSubstructure,
  setSelectedSubstructure,
  selectedIncidences,
  setSelectedIncidences,
  redaccion,
  setRedaccion,
  isLoaded,
}) => {
  const [estructuras, setEstructuras] = useState([]);
  const [subestructuras, setSubestructuras] = useState([]);
  const [incidencias, setIncidencias] = useState([]);
  const [hasSubestructuras, setHasSubestructuras] = useState(true);
  const [currentIncidence, setCurrentIncidence] = useState([]);

  const generateRedaccion = useCallback((structure, substructure, incidences) => {
    if (incidences.length === 0) return '';

    const baseRedaccion = getRedaccion(structure, hasSubestructuras ? substructure : structure, incidences[0]);
    const parts = baseRedaccion.split(',').map(part => part.trim());

    const prefix = parts[0];
    let zone = '';
    if (parts.length > 2 && parts[1].startsWith('zona')) {
      zone = parts[1];
    }

    const uniqueIncidences = [...new Set(incidences)];

    let incidencesText = '';
    if (uniqueIncidences.length === 1) {
      incidencesText = `incidencia ${uniqueIncidences[0].toLowerCase()}`;
    } else if (uniqueIncidences.length >= 2) {
      const lastIncidence = uniqueIncidences.pop();
      incidencesText = `incidencias ${uniqueIncidences.map(inc => inc.toLowerCase()).join(', ')} y ${lastIncidence.toLowerCase()}`;
    }

    return zone
      ? `${prefix}, ${zone}, ${incidencesText}.`
      : `${prefix}, ${incidencesText}.`;
  }, [hasSubestructuras]);

  useEffect(() => {
    if (isLoaded) {
      const estructurasData = getEstructuras();
      setEstructuras(estructurasData);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded && selectedStructure) {
      const hasSubstructures = tieneSubestructuras(selectedStructure);
      setHasSubestructuras(hasSubstructures);
      if (hasSubstructures) {
        const subestructurasData = getSubestructuras(selectedStructure);
        setSubestructuras(subestructurasData);
        setIncidencias([]); // Limpiar incidencias al seleccionar una nueva estructura con subestructuras
      } else {
        setSubestructuras([]);
        const incidenciasData = getIncidencias(selectedStructure, selectedStructure);
        setIncidencias(incidenciasData.map(inc => ({ ...inc, label: inc.label.toLowerCase() })));
      }
    } else {
      setSubestructuras([]);
      setIncidencias([]);
    }
  }, [selectedStructure, isLoaded]);

  useEffect(() => {
    if (isLoaded && selectedStructure) {
      if (hasSubestructuras && selectedSubstructure) {
        const incidenciasData = getIncidencias(selectedStructure, selectedSubstructure);
        setIncidencias(incidenciasData.map(inc => ({ ...inc, label: inc.label.toLowerCase() })));
      } else if (!hasSubestructuras) {
        const incidenciasData = getIncidencias(selectedStructure, selectedStructure);
        setIncidencias(incidenciasData.map(inc => ({ ...inc, label: inc.label.toLowerCase() })));
      }
    } else {
      setIncidencias([]);
    }
  }, [selectedStructure, selectedSubstructure, hasSubestructuras, isLoaded]);

  const handleAddIncidence = useCallback(() => {
    if (currentIncidence.length > 0) {
      setRedaccion(prevRedaccion => {
        const prevLines = prevRedaccion ? prevRedaccion.split('\n') : [];

        let updatedLines = [...prevLines];
        let existingStructureLines = updatedLines.filter(line =>
          line.startsWith(selectedStructure)
        );

        if (existingStructureLines.length > 0) {
          let exactMatchIndex = existingStructureLines.findIndex(line =>
            (!hasSubestructuras || line.includes(selectedSubstructure))
          );

          if (exactMatchIndex !== -1) {
            const existingLine = existingStructureLines[exactMatchIndex];
            const existingIncidences = existingLine.split('incidencias')[1]?.split(/[,y]+/).map(inc => inc.trim()) || [];
            const combinedIncidences = [...new Set([...existingIncidences, ...currentIncidence])];
            const newRedaccion = generateRedaccion(selectedStructure, selectedSubstructure, combinedIncidences);

            updatedLines[updatedLines.indexOf(existingLine)] = newRedaccion;
          } else {
            const newRedaccion = generateRedaccion(selectedStructure, selectedSubstructure, currentIncidence);

            const lastIndex = updatedLines.lastIndexOf(existingStructureLines[existingStructureLines.length - 1]);
            updatedLines.splice(lastIndex + 1, 0, newRedaccion);
          }
        } else {
          const newRedaccion = generateRedaccion(selectedStructure, selectedSubstructure, currentIncidence);
          updatedLines.push(newRedaccion);
        }

        updatedLines = [...new Set(updatedLines)];

        return updatedLines.join('\n');
      });

      setSelectedIncidences(prevIncidences => [...new Set([...prevIncidences, ...currentIncidence])]);
      setCurrentIncidence([]);
      setSelectedStructure('');
      setSelectedSubstructure('');
    }
  }, [currentIncidence, selectedStructure, selectedSubstructure, hasSubestructuras, generateRedaccion, setSelectedIncidences, setSelectedStructure, setSelectedSubstructure, setRedaccion]);

  return (
    <>
      <FloatingSelect
        label="Seleccionar estructura"
        value={selectedStructure}
        onChange={value => {
          setSelectedStructure(value);
          setSelectedSubstructure('');
          setCurrentIncidence([]);
          if (!value) {
            setSubestructuras([]);
            setIncidencias([]);
          } else {
            const hasSubstructures = tieneSubestructuras(value);
            setHasSubestructuras(hasSubstructures);
            if (hasSubstructures) {
              const subestructurasData = getSubestructuras(value);
              setSubestructuras(subestructurasData);
              setIncidencias([]); // Limpiar incidencias al seleccionar una nueva estructura con subestructuras
            } else {
              const incidenciasData = getIncidencias(value, value);
              setIncidencias(incidenciasData.map(inc => ({ ...inc, label: inc.label.toLowerCase() })));
            }
          }
        }}
        options={estructuras}
        fullWidth
        onSearch={(text) => {
          const filteredEstructuras = getEstructuras().filter(e =>
            e.label.toLowerCase().includes(text.toLowerCase())
          );
          setEstructuras(filteredEstructuras);
        }}
      />

      <FloatingSelect
        label="Seleccionar subestructura"
        value={selectedSubstructure}
        onChange={value => {
          setSelectedSubstructure(value);
          setCurrentIncidence([]);
          if (!value) {
            setIncidencias([]);
          } else {
            const incidenciasData = getIncidencias(selectedStructure, value);
            setIncidencias(incidenciasData.map(inc => ({ ...inc, label: inc.label.toLowerCase() })));
          }
        }}
        options={subestructuras}
        disabled={!selectedStructure || !hasSubestructuras}
        fullWidth
        onSearch={(text) => {
          const filteredSubestructuras = getSubestructuras(selectedStructure).filter(e =>
            e.label.toLowerCase().includes(text.toLowerCase())
          );
          setSubestructuras(filteredSubestructuras);
        }}
      />

      <FloatingSelect
        label="Incidencias"
        mode="multiple"
        onChange={values => {
          setCurrentIncidence(values);
        }}
        value={currentIncidence}
        options={incidencias}
        disabled={!selectedStructure || (hasSubestructuras && !selectedSubstructure)}
        fullWidth
        allowCustomOption
        onSearch={(text) => {
          const filteredIncidencias = getIncidencias(selectedStructure, selectedSubstructure).filter(e =>
            e.label.toLowerCase().includes(text.toLowerCase())
          );
          const customOption = text ? [{ value: text, label: text.toLowerCase() }] : [];
          setIncidencias([...customOption, ...filteredIncidencias.map(inc => ({ ...inc, label: inc.label.toLowerCase() }))]);
        }}
      />

      <Row justify="start">
        <Button icon={<PlusCircleOutlined />} onClick={handleAddIncidence} disabled={currentIncidence.length === 0} style={{ float: 'right', marginBottom: '20px', marginTop: '-10px' }}>
          Agregar
        </Button>
      </Row>

      <TextArea
        className="custom-textarea"
        value={redaccion}
        placeholder="Descripción"
        onChange={e => setRedaccion(e.target.value)}
        style={{ width: '100%', marginBottom: '10px' }}
        rows={4}
      />
    </>
  );
};

export default RequestSection;