import React, { useState } from 'react';
import { Col, Row, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import samu from '../assets/images/postconf.webp';
import '../assets/styles/LoginPage.css';

const { Option } = Select;

const PostConfirmation = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState(null); // Estado para un solo archivo
    const [errorMessage, setErrorMessage] = useState('');
    const [prefijo, setPrefijo] = useState(undefined); 
    const fileInputRef = React.createRef(); // Referencia al input de archivo

    const handleContinue = () => {
        navigate('/espera');
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile && selectedFile.type === 'application/pdf') {
            if (selectedFile.size > 5 * 1024 * 1024) {
                setErrorMessage('El archivo supera el tamaño máximo de 5MB.');
                setFile(null);
            } else {
                setFile(selectedFile);
                setErrorMessage('');
            }
        } else {
            setErrorMessage('Solo se permiten archivos PDF.');
            setFile(null);
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        fileInputRef.current.value = ''; // Restablece el valor del input de archivo
    };

    const handlePrefijoChange = (value) => {
        setPrefijo(value);
    };

    return (
        <div className="body">
            <Row className="content">
                <Col lg={10} sm={24} xs={24} className="newleftside">
                    <div>
                        <img src={samu} alt="ovejero" className="dogimage2" />
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24}>
                        <Col span={24} className="rightside-container6">
                            <div className="container2">
                                <p className="bluetitle decrease left">Ya casi estamos ...</p>
                                <p className="subtitle3">Necesitamos pedirte un par de datos para mejorar la experiencia:</p>
                                <Row justify={'space-between'}>
                                    <Col className="custom-col-24">
                                        <Input placeholder="Número de Teléfono" className="loginInput3" />
                                    </Col>
                                </Row>
                                <Row justify={'space-between'}>
                                    <Col className="custom-col-11-6-prefijo">
                                        <Select
                                            placeholder="Prefijo"
                                            className="SelectInput3"
                                            value={prefijo}
                                            onChange={handlePrefijoChange}
                                        >
                                            <Option value="prefijo1">Prefijo 1</Option>
                                            <Option value="prefijo2">Prefijo 2</Option>
                                            <Option value="prefijo3">Prefijo 3</Option>
                                            <Option value="prefijo4">Prefijo 4</Option>
                                            <Option value="prefijo5">Prefijo 5</Option>
                                        </Select>
                                    </Col>
                                    <Col className="custom-col-11-6-nombre">
                                        <Input placeholder="Nombre" className="loginInput3" />
                                    </Col>
                                </Row>
                                <Row justify={'space-between'}>
                                    <Col className="custom-col-24">
                                        <label className="custom-file-upload">
                                            <input
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handleFileChange}
                                                ref={fileInputRef}
                                                multiple={false}
                                                style={{ display: 'none' }}
                                            />
                                            <div className="file-upload-btn">
                                                {file ? (
                                                    <div className="file-info">
                                                        <span>{file.name}</span>
                                                        <CloseOutlined
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleRemoveFile();
                                                            }}
                                                            style={{
                                                                color: '#ff4d4f',
                                                                marginLeft: '8px',
                                                                cursor: 'pointer',
                                                            }}
                                                        />

                                                    </div>
                                                ) : (
                                                    <>
                                                        <UploadOutlined style={{ marginRight: '8px' }} />
                                                        Subir Reporte PDF
                                                    </>
                                                )}
                                            </div>
                                        </label>
                                        {errorMessage && (
                                            <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
                                        )}
                                    </Col>
                                </Row>
                                <Row justify={'space-between'}>
                                    <Col className="custom-col-24">
                                        <Input placeholder="Matrícula" className="loginInput3" />
                                    </Col>
                                </Row>
                                <button className="button6" onClick={handleContinue}>
                                    Continuar
                                </button>
                            </div>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default PostConfirmation;
