import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doSignOut } from "../firebase/auth";
import { useAuth } from '../contexts/authContext';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import logo from '../assets/images/logo.svg';
import defaultAvatar from '../assets/images/default-avatar.png';
import '../assets/styles/Navbar.css';

function Navbar() {
    const navigate = useNavigate();
    const { userLoggedIn, currentUser } = useAuth();
    const [avatarSrc, setAvatarSrc] = useState(defaultAvatar);

    useEffect(() => {
        if (currentUser?.photoURL) {
            const img = new Image();
            img.onload = () => {
                setAvatarSrc(currentUser.photoURL);
            };
            img.onerror = () => {
                console.error("Failed to load photo URL, using default avatar.");
                setAvatarSrc(defaultAvatar);
            };
            img.src = currentUser.photoURL;
        } else {
            setAvatarSrc(defaultAvatar);
        }
    }, [currentUser]);


    const handleLogout = async () => {
        await doSignOut();
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const menu = (
        <div className="custom-dropdown-menu">
            <button onClick={() => navigate('/dashboard')}>Mis estudios</button>
            <div className="divider"></div>
            <button onClick={handleLogout}>Cerrar Sesión</button>
        </div>
    );

    return (
        <nav className="navbar">
            <div className="navbar-logo-container" onClick={handleLogoClick}>
                <img src={logo} alt="Logo" className="navbar-logo" />
            </div>
            {userLoggedIn && currentUser && (
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                    <div className="user-profile">
                        <div className="user-info">
                            <div className="user-avatar-name">
                                <img
                                    src={avatarSrc}
                                    alt="User Avatar"
                                    className="user-avatar"
                                    referrerPolicy="no-referrer"
                                />

                                <span className="user-name">{currentUser.displayName || 'Usuario'}</span>
                            </div>
                            <DownOutlined className="dropdown-arrow" />
                        </div>
                    </div>
                </Dropdown>
            )}
        </nav>
    );
}

export default Navbar;

