const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;
export const getObservations = async (prompt, token) => {
    try {
      const response = await fetch(`${REACT_APP_BFF_URL}/get-observations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ prompt: prompt }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || 'Network response was not ok');
      }

      return data;
    } catch (error) {
      console.error('Error fetching observations:', error);
      throw error;
    }
  };