import React, { createContext, useState } from 'react';

// Create the context
export const ImageContext = createContext();

// Create the provider component
export const ImageProvider = ({ children }) => {
    const [images, setImages] = useState([]);

    // Function to add new images
    const addImages = (newImages) => {
        setImages(prevImages => {
            const updatedImages = [...prevImages, ...newImages].slice(0, 70); // Limit to 70 images
            return updatedImages;
        });
    };

    // Function to remove an image by index
    const removeImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    // Function to clear all images
    const clearImages = () => {
        setImages([]);
    };

    return (
        <ImageContext.Provider value={{ images, addImages, removeImage, clearImages, setImages }}>
            {children}
        </ImageContext.Provider>
    );
};

