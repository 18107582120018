import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import golden from '../assets/images/golden.webp';
import '../assets/styles/LoginPage.css';
import { doSignInWithGoogle } from '../firebase/auth';
import { useAuth } from '../contexts/authContext';
import {createUser} from "../services/AuthServices";

const LoginPage = () => {
    const { userLoggedIn } = useAuth();
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = () => {
            if (userLoggedIn) {
                navigate('/dashboard');
            } else {
                setIsCheckingAuth(false); 
            }
        };
        checkAuth();
    }, [navigate, userLoggedIn]);

    const Login = async () => {
        if (!isSigningIn) {
            setIsSigningIn(true);
            doSignInWithGoogle()
                .then(result => createUser(result.user.uid, result.user.email,
                    result._tokenResponse.firstName, result._tokenResponse.lastName, result.user.photoURL))
                .then(() => navigate('/dashboard'))
                .catch(error => {
                    setIsSigningIn(false);
                    console.error('Error signing in:', error);
                });
        }
    };

    if (isCheckingAuth) {
        return <div>Verificando autenticación...</div>; // Mostrar un indicador de carga
    }

    return (
        <div className="body">
            <Row className="content">
                <Col lg={10} sm={24} xs={24} className="newleftside">
                    <div>
                        <img src={golden} alt="ovejero" className="dogimage2" />
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24}>
                        <Col span={24} className="rightside-container2">
                            <div className="logo-container spacing2">
                                <p className="bluetitle decrease">Comienza tu historia junto a Diagnovet</p>
                            </div>
                            <div className="button-container">
                                <Button
                                    className="google-login-button"
                                    onClick={Login} // Asegúrate de que 'googleLogin' está correctamente importado y definido
                                >
                                    <img
                                        src="https://developers.google.com/identity/images/g-logo.png"
                                        alt="Google Logo"
                                        className="google-logo"
                                    />
                                    <span className="google-login-text">Iniciar con Google</span>
                                </Button>
                            </div>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage;
