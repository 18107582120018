import React, { useState, useMemo, useEffect } from 'react';
import { Select, Popconfirm } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import '../assets/styles/FloatingSelect.css';

const { Option } = Select;

const FloatingSelect = ({ 
  label, 
  value, 
  onChange, 
  options, 
  error: propError, 
  touched, 
  required, 
  fullWidth, 
  allowCustomOption = false, 
  mode,
  onDeleteOption,
  ...props 
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(propError);

  useEffect(() => {
    setError(propError);
  }, [propError]);

  const filteredOptions = useMemo(() => {
    return options
      .filter(option => {
        const optionLabel = option.label || option.value || '';
        return typeof optionLabel === 'string' && optionLabel.toLowerCase().includes(searchValue.toLowerCase());
      })
      .sort((a, b) => {
        const labelA = (a.label || a.value || '').toString().toLowerCase();
        const labelB = (b.label || b.value || '').toString().toLowerCase();
        const aStartsWith = labelA.startsWith(searchValue.toLowerCase());
        const bStartsWith = labelB.startsWith(searchValue.toLowerCase());
        
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;
        return labelA.localeCompare(labelB);
      });
  }, [options, searchValue]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleChange = (newValue) => {
    setError(false);
    onChange(newValue);
    setSearchValue('');
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (mode === 'multiple' && allowCustomOption && searchValue && !options.find(opt => (opt.value || '').toString().toLowerCase() === searchValue.toLowerCase())) {
      onChange([...(Array.isArray(value) ? value : []), searchValue]);
      setSearchValue('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchValue && filteredOptions.length === 0 && allowCustomOption) {
      event.preventDefault();
      if (mode === 'multiple') {
        onChange([...(value || []), searchValue]);
      } else {
        onChange(searchValue);
      }
      setSearchValue('');
    }
  };

  const handleClear = () => {
    onChange(mode === 'multiple' ? [] : undefined);
    setSearchValue('');
  };

  const handleDeleteOption = (optionValue) => {
    if (mode === 'multiple') {
      const newValue = Array.isArray(value) ? value.filter(v => v !== optionValue) : [];
      onChange(newValue);
    } else if (value === optionValue) {
      onChange(undefined);
    }
    if (onDeleteOption) {
      onDeleteOption(optionValue);
    }
  };

  const renderOption = (option) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {option.label || option.value}
      {onDeleteOption && (
        <Popconfirm
          title="¿Estás seguro de que quieres eliminar este profesional?"
          onConfirm={(e) => {
            e.stopPropagation();
            handleDeleteOption(option.value);
          }}
          okText="Sí"
          cancelText="No"
        >
          <CloseCircleOutlined
            style={{ color: 'red', marginLeft: '10px' }}
            onClick={(e) => e.stopPropagation()}
          />
        </Popconfirm>
      )}
    </div>
  );

  return (
    <div className={`floating-select ${isFocused || (value && (Array.isArray(value) ? value.length > 0 : value)) ? 'focused' : ''} ${error ? 'error' : ''} ${required ? 'required' : ''} ${fullWidth ? 'full-width' : ''} ${mode === 'multiple' ? 'multiple' : ''}`}>
      <Select
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        showSearch
        onSearch={handleSearch}
        filterOption={false}
        placeholder=" "
        onKeyDown={handleKeyDown}
        allowClear={true}
        onClear={handleClear}
        mode={mode}
        {...props}
      >
        {filteredOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            {renderOption(option)}
          </Option>
        ))}
        {allowCustomOption && mode === 'multiple' && searchValue && !filteredOptions.find(opt => (opt.label || opt.value || '').toString().toLowerCase() === searchValue.toLowerCase()) && (
          <Option value={searchValue} key={searchValue}>
            Agregar "{searchValue}"
          </Option>
        )}
      </Select>
      <label>{label}{required && '*'}</label>
    </div>
  );
};

export default FloatingSelect;