import React from "react"
import { Modal, Table } from "antd"

const ReportModal = ({ report, isOpen, onClose }) => {
  if (!report) return null

  const columns = [
    {
      title: "Campo",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (value) => renderValue(value),
    },
  ]

  const data = Object.entries(report).map(([key, value], index) => ({
    key: index,
    field: key,
    value: value,
  }))

  return (
    <Modal
      title={`Detalles del Reporte - ${report.patient?.name}`}
      open={isOpen}
      onCancel={onClose}
      width={800}
      footer={null}
    >
      <Table columns={columns} dataSource={data} pagination={false} scroll={{ y: 400 }} />
    </Modal>
  )
}

const renderValue = (value) => {
  if (typeof value === "object" && value !== null) {
    return (
      <Table
        columns={[
          { title: "Campo", dataIndex: "field", key: "field" },
          { title: "Valor", dataIndex: "value", key: "value", render: (v) => renderValue(v) },
        ]}
        dataSource={Object.entries(value).map(([subKey, subValue], index) => ({
          key: index,
          field: subKey,
          value: subValue,
        }))}
        pagination={false}
        size="small"
      />
    )
  }
  return String(value)
}

export default ReportModal

