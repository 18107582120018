import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import AnalizarPaciente from './pages/AnalizarPaciente';
import Analisis from './pages/Analisis';
import PreConfirmation from './pages/PreConfirmation';
import Await from './pages/Await';
import PostConfirmation from './pages/PostConfirmation';
import {AuthProvider} from "./contexts/authContext";
import PrivateRoute from './contexts/routeContext/PrivateRoute';
import {ImageProvider} from "./contexts/imageContext/imageContext";
import Dashboard from './pages/Dashboard';

function App() {

    return (
        <AuthProvider>
            <ImageProvider>
                <Router>
                    <div className="App">
                        <Navbar/>
                        <Routes>
                            <Route path="/" element={<LandingPage/>}/>
                            <Route path="/login" element={<LoginPage/>}/>
                            <Route path="/dashboard" element={
                                <PrivateRoute>
                                    <Dashboard/>
                                </PrivateRoute>
                            }/>
                            <Route path="/analizar" element={
                                <PrivateRoute>
                                    <AnalizarPaciente/>
                                </PrivateRoute>
                            }/>
                            <Route path="/analisis" element={
                                <PrivateRoute>
                                    <Analisis/>
                                </PrivateRoute>
                            }/>
                            <Route path="/preconfirmacion" element={
                                <PrivateRoute>
                                    <PreConfirmation/>
                                </PrivateRoute>
                            }/>
                            <Route path="/postconfirmacion" element={
                                <PrivateRoute>
                                    <PostConfirmation/>
                                </PrivateRoute>
                            }/>
                            <Route path="/espera" element={
                                <PrivateRoute>
                                    <Await/>
                                </PrivateRoute>
                            }/>
                        </Routes>
                        <Footer/>
                    </div>
                </Router>
            </ImageProvider>
        </AuthProvider>
    );
}

export default App;