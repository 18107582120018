import React, {createContext, useContext, useEffect, useState} from "react";
import {auth} from "../../firebase/firebase.js";
import {onAuthStateChanged} from "firebase/auth";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userLoggedIn, setUserLoggedIn] = useState(false);

    async function initializeUser(user) {
        if (user) {
            setCurrentUser(user);
            setUserLoggedIn(true);
        } else {
            setCurrentUser(null);
            setUserLoggedIn(false);
        }
        setLoading(false);
    }

    async function getToken() {
        if (currentUser) {
            try {
                // Force refresh to ensure the token is fresh
                const token = await currentUser.getIdToken(true);
                return token;
            } catch (error) {
                console.error("Error fetching ID token:", error);
                return null;
            }
        }
        return null;
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initializeUser);
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        userLoggedIn,
        loading,
        getToken,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
